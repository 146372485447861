import { z } from 'zod';

export const DEFAULT_TRANSACTIONAL_EMAIL_ADDRESS = 'team@noreply.tickrr.io';
export const DEFAULT_BATCH_SEND_EMAIL_ADDRESS = 'marketing@noreply.tickrr.io';

export const EmailTemplateAliasValidator = z.enum([
	'bulk-email',
	'new-user-day-three',
	'new-user-day-two',
	'new-user-day-five',
	'new-user-day-seven',
	'new-comment',
	'new-post',
	'webhook-error',
	'new-user-welcome',
	'price-alert-triggered'
]);

export type EmailTemplateAlias = z.infer<typeof EmailTemplateAliasValidator>;

export const DEFAULT_EMAIL_VALUES = {
	baseUrl: 'https://tickrr.io',
	// Note: this email is the only that has a BIMI avatar in Gmail (since our account is set up + verified).
	fromEmail: `Tickrr <${DEFAULT_TRANSACTIONAL_EMAIL_ADDRESS}>`,
	homePage: 'https://tickrr.io/home',
	loginUrl: 'https://tickrr.io/signin',
	productName: 'Tickrr',
	senderName: 'The Tickrr Team',
	subscribeUrl: 'https://tickrr.io/subscribe',
	supportEmail: 'team@tickrr.io'
} as const;

export const EMAIL_TEMPLATE_ALIASES = {
	bulkEmail: 'bulk-email',
	newComment: 'new-comment',
	newPost: 'new-post',
	newUserDayFive: 'new-user-day-five',
	newUserDaySeven: 'new-user-day-seven',
	newUserDayThree: 'new-user-day-three',
	newUserDayTwo: 'new-user-day-two',
	newUserWelcome: 'new-user-welcome',
	priceAlertTriggered: 'price-alert-triggered',
	webhookError: 'webhook-error'
} as const;

export const PERSONALIZED_EMAIL_SENDER = {
	fromEmail: `Austin at Tickrr <${DEFAULT_TRANSACTIONAL_EMAIL_ADDRESS}>`,
	senderName: 'Austin'
} as const;
